const genID = (length) => {
  if (!length) {
    length = 8;
  }

  let array = new Uint32Array(length);
  window.crypto.getRandomValues(array);

  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += array[i].toString(16)[0];
  }

  return str;
};

export { genID };
