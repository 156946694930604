import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import moment from "moment";
import Moment from "react-moment";
import DatePicker from "react-date-picker";

import DismissibleAlert from "@/_components/DismissibleAlert";
import BookingsOverviewTable from "@/_components/BookingsOverviewTable";

import { levelToVariant } from "@/_utils";
import { BookingsOverviewService } from "@/_services";

class BookingsOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      alerts: [],
      year: moment().year(),
      month: moment().month() + 1,
    };
  }

  async componentDidMount() {
    this.fetchBookings(moment());
  }

  async fetchBookings(date) {
    this.setState({
      alerts: this.state.alerts.filter((alert) => {
        return !alert.key.includes("loading_bookings");
      }),
    });
    this.setState({
      alerts: this.state.alerts.concat({
        variant: "info",
        key: "info_loading_bookings",
        message: "Loading bookings for " + date.format("MMMM YYYY") + "...",
      }),
    });

    const resolved = (bookings) => {
      bookings.sort((a, b) => a.starttime.localeCompare(b.starttime, "nl"));
      this.setState({ bookings: bookings });
      if (bookings.length === 0) {
        const alert = {
          variant: "info",
          message: "No bookings found for " + date.format("MMMM YYYY") + ".",
          key: "result_loading_bookings_" + this.state.alerts.length,
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    const rejected = (error) => {
      console.error(error);
      if ("exception" in error) {
        const alert = {
          variant: levelToVariant[error.level],
          message: error.message,
          key: "exception_loading_bookings_" + this.state.alerts.length,
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    await BookingsOverviewService.getAllForMonth(
      date.year(),
      date.month() + 1
    ).then(resolved, rejected);

    this.setState({ year: date.year(), month: date.month() + 1 });

    this.setState({
      alerts: this.state.alerts.filter((alert) => {
        return !alert.key.startsWith("info_loading_bookings");
      }),
    });
  }

  dateChanged = async (date) => {
    this.fetchBookings(moment(date));
  };

  populateBookingsOverviewTableData() {
    const tableData = this.state.bookings
      .filter((booking) => !['CANCELED', 'FORCE_CANCELED'].includes(booking.status))
      .map((booking) => {
        const starttime = moment.utc(booking.starttime).local();

        const row = {};
        row.registered = moment
          .utc(booking.updated)
          .local()
          .format("YYYY-MM-DD HH:mm");
        row.code = booking.code;
        row.serviceDate = starttime.format("YYYY-MM-DD");
        row.serviceStarttime = starttime.format("HH:mm");
        row.customerName = booking.customerName;
        row.gamemaster = [];
        row.service = booking.service.title;
        row.serviceVariation = booking.serviceVariation.title;
        row.servicePrice = booking.serviceVariation.price;
        booking.consumptions.forEach(
          (consumption) =>
            (row[consumption.category + "Price"] =
              consumption.tariff * consumption.amount)
        );
        booking.payments.forEach((payment) => {
          var paymentmethod = payment.method.replace(' ', '_') + "Payment";
          var subtotal = row[paymentmethod] || 0;
          row[paymentmethod] = subtotal + payment.amount;
          if (row.gamemaster.indexOf(payment.gamemaster) === -1) {
            row.gamemaster.push(payment.gamemaster);
          }
        });
        row.gamemaster = row.gamemaster.join();
        return row;
      });
    return tableData;
  }

  render() {
    const alerts = this.state.alerts.map((alert, index) => {
      return (
        <DismissibleAlert
          key={alert.key + "_" + index}
          variant={alert.variant}
          dismissible
        >
          {alert.message.startsWith("Loading") && (
            <>
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">{alert.message}</span>
              </Spinner>{" "}
            </>
          )}
          {alert.message}
        </DismissibleAlert>
      );
    });

    return (
      <>
        <h2>
          Bookings overview for{" "}
          <Moment interval={0} format="MMMM YYYY" local>
            {this.state.year + "-" + this.state.month}
          </Moment>
        </h2>
        <div className="mb-1">
          <DatePicker
            value={new Date(this.state.year, this.state.month - 1, 1)}
            format="y-MM"
            minDetail="year"
            maxDetail="year"
            onChange={this.dateChanged}
          />
        </div>
        <Row className="mb-2">
          <Col>{alerts}</Col>
        </Row>
        <BookingsOverviewTable
          bookings={this.populateBookingsOverviewTableData()}
          year={this.state.year}
          month={this.state.month}
        />
      </>
    );
  }
}

export { BookingsOverview };
