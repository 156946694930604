import { Subject } from "rxjs";

const currentuser = new Subject();

export const CurrentuserService = {
  setCurrentuser: (username, role) =>
    currentuser.next({ username: username, role: role }),
  clearCurrentuser: () => currentuser.next(),
  getCurrentuser: () => currentuser.asObservable(),
};
