import {
  CAMPAIGN_NAME,
  CUSTOMER_NAME,
  SERVICE_ID,
  VARIATION_ID,
} from "./fieldNames";

export default {
  [CAMPAIGN_NAME]: "",
  [CUSTOMER_NAME]: "",
  [SERVICE_ID]: "",
  [VARIATION_ID]: "",
};
