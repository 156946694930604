import * as Yup from "yup";
import {
  COMMENT,
  CONSUMPTIONS,
  EMAILADDRESS,
  PATCHBASICS,
  PHONENUMBER,
  STARTTIME,
} from "./fieldNames";
import bookingpatchbasicsValidationSchema from "@/_forms/_subforms/BookingPatchBasics/validationSchema";
import bookingconsumptionsValidationSchema from "@/_forms/_subforms/BookingConsumptions/validationSchema";

export default Yup.object({
  [PATCHBASICS]: bookingpatchbasicsValidationSchema,
  [CONSUMPTIONS]: bookingconsumptionsValidationSchema,
  [COMMENT]: Yup.string(),
  [STARTTIME]: Yup.string().required("Required"),
  [EMAILADDRESS]: Yup.string().email(),
  [PHONENUMBER]: Yup.string(),
});
