import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import { MdRemoveCircleOutline } from "react-icons/md";

import { formatAmount } from "@/_utils";

import RegisterBookingPaymentForm from "@/_forms/RegisterBookingPaymentForm";

class RegisterBookingPaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.props.onHide;
    this.state = { payments: [] };
  }

  handleSave = (bookingID, payments) => {
    this.props.onSave(bookingID, payments);
    this.setState({ payments: [] });
  };

  addPayment = (payment, bag) => {
    const payments = this.state.payments;
    this.setState({ payments: payments.concat(payment) });
    bag.resetForm();
    bag.setSubmitting(false);
  };

  removePayment = (index) => {
    var payments = this.state.payments.slice();
    payments.splice(index, 1);
    this.setState({ payments: payments });
  };

  render() {
    const payments = this.state.payments.map((payment, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            {payment.method.charAt(0).toUpperCase() + payment.method.slice(1)}
          </td>
          <td>{formatAmount(payment.amount)}</td>
          <td>
            <Button
              onClick={() => {
                this.removePayment(index);
              }}
              variant="outline-danger"
              size="sm"
            >
              <MdRemoveCircleOutline />
            </Button>
          </td>
        </tr>
      );
    });

    const totalPrice =
      this.props.bookingcard === null
        ? 0
        : this.props.bookingcard.props.booking.totalPrice;

    const totalPaid = this.state.payments
      .map((payment) => {
        return parseFloat(payment.amount);
      })
      .reduce((x, y) => {
        return x + y;
      }, 0);

    return (
      <Modal
        show={this.props.bookingcard != null}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="lg"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Register booking payments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RegisterBookingPaymentForm
            onAdd={this.addPayment}
            bookingcard={this.props.bookingcard}
          />
          <Table striped hover responsive size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Payment method</th>
                <th>Amount</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>{payments}</tbody>
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <strong>TOTAL:</strong>
                </td>
                <td colSpan={2}>
                  <strong>{formatAmount(totalPaid)}</strong> /{" "}
                  {formatAmount(totalPrice)}
                </td>
              </tr>
            </tfoot>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCancel}>
            Cancel
          </Button>
          {totalPaid === totalPrice && (
            <Button
              variant="primary"
              onClick={() => {
                this.handleSave(
                  this.props.bookingcard.props.booking._id,
                  this.state.payments
                );
              }}
            >
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RegisterBookingPaymentModal;
