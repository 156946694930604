import config from "react-global-configuration";
import { authHeaders, handleResponse, fetchWithRefresh } from "@/_utils";

function getAll() {
  const apiurl = config.get("API_BASE_URL") + "/pos/paymentmethods";
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

export const PaymentMethodService = { getAll };
