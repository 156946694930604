import {
  COMMENT,
  CONSUMPTIONS,
  EMAILADDRESS,
  PATCHBASICS,
  PHONENUMBER,
  STARTTIME,
} from "./fieldNames";
import bookingpatchbasicsDefaultValues from "@/_forms/_subforms/BookingPatchBasics/defaultValues";
import bookingconsumptionsDefaultValues from "@/_forms/_subforms/BookingConsumptions/defaultValues";

export default {
  [PATCHBASICS]: bookingpatchbasicsDefaultValues,
  [CONSUMPTIONS]: bookingconsumptionsDefaultValues,
  [STARTTIME]: "",
  [COMMENT]: "",
  [EMAILADDRESS]: "",
  [PHONENUMBER]: "",
};
