import rules from "@/rbac-rules";

const check = (rules, role, action) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  if (permissions.includes(action)) {
    // rule not provided for action
    return true;
  }

  return false;
};

const Can = (props) => {
  const role = localStorage.getItem("role");
  return check(rules, role, props.perform) ? props.yes() : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export { Can };
