import React from "react";

import { Route, Redirect } from "react-router-dom";

import { Can } from "@/_utils";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({ component: Component, permission, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const username = localStorage.getItem("username");
      if (!username) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      // authorised so return component
      return (
        <Can
          perform={permission}
          yes={() => <Component {...props} />}
          no={() => <p>Insufficient permissions</p>}
        />
      );
    }}
  />
);
