import config from "react-global-configuration";
import { authHeaders, handleResponse, fetchWithRefresh } from "@/_utils";

function getAll(serviceID) {
  const apiurl =
    config.get("API_BASE_URL") + "/pos/servicevariations/" + serviceID;
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

export const ServiceVariationService = { getAll };
