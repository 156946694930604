import React from "react";
import ReactDOM from "react-dom";
import config from "react-global-configuration";

import "bootstrap/dist/css/bootstrap.min.css";

import configuration from "@/config";
import App from "@/App";


config.set(configuration);
ReactDOM.render(<App />, document.getElementById("root"));
