import { AuthenticationService } from "@/_services";
import { authHeaders } from ".";

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        AuthenticationService.logout();
      }

      const defaultError = {
        level: "error",
        message: response.statusText,
        exception: true,
      };
      const error = data || defaultError;
      return Promise.reject(error);
    }

    return data;
  });
}

function fetchWithRefresh(url, options) {
  return fetch(url, options).then(async (response) => {
    if (response.status === 401) {
      await AuthenticationService.refresh();
      Object.assign(options.headers, authHeaders());
      return fetch(url, options);
    }
    return response;
  });
}

export { handleResponse, fetchWithRefresh };
