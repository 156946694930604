import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import get from "lodash/get";

import {
  DRINKS_NONALCOHOLIC,
  DRINKS_ALCOHOLIC,
  SNACKS,
} from "./fieldNames";

class BookingConsumptionsSubForm extends React.Component {
  withNamespace(fieldName) {
    const namespace = this.props.namespace;
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  }

  render() {
    const {
      handleChange,
      handleBlur,
      touched,
      errors,
      values,
    } = this.props;

    return (
      <>
        <Form.Row>
          <Col>
            <Form.Group controlId={this.withNamespace(DRINKS_ALCOHOLIC)}>
              <Form.Label>Alcoholic drinks</Form.Label>
              <Form.Control
                type="number"
                min={0}
                name={this.withNamespace(DRINKS_ALCOHOLIC)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  get(touched, this.withNamespace(DRINKS_ALCOHOLIC)) &&
                  get(errors, this.withNamespace(DRINKS_ALCOHOLIC))
                    ? "is-invalid"
                    : null
                }
                value={get(values, this.withNamespace(DRINKS_ALCOHOLIC))}
                isValid={
                  get(touched, this.withNamespace(DRINKS_ALCOHOLIC)) &&
                  !get(errors, this.withNamespace(DRINKS_ALCOHOLIC))
                }
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, this.withNamespace(DRINKS_ALCOHOLIC))}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={this.withNamespace(DRINKS_NONALCOHOLIC)}>
              <Form.Label>Nonalcoholic drinks</Form.Label>
              <Form.Control
                type="number"
                min={0}
                name={this.withNamespace(DRINKS_NONALCOHOLIC)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  get(touched, this.withNamespace(DRINKS_NONALCOHOLIC)) &&
                  get(errors, this.withNamespace(DRINKS_NONALCOHOLIC))
                    ? "is-invalid"
                    : null
                }
                value={get(values, this.withNamespace(DRINKS_NONALCOHOLIC))}
                isValid={
                  get(touched, this.withNamespace(DRINKS_NONALCOHOLIC)) &&
                  !get(errors, this.withNamespace(DRINKS_NONALCOHOLIC))
                }
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, this.withNamespace(DRINKS_NONALCOHOLIC))}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={this.withNamespace(SNACKS)}>
              <Form.Label>Snacks</Form.Label>
              <Form.Control
                type="number"
                min={0}
                name={this.withNamespace(SNACKS)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  get(touched, this.withNamespace(SNACKS)) &&
                  get(errors, this.withNamespace(SNACKS))
                    ? "is-invalid"
                    : null
                }
                value={get(values, this.withNamespace(SNACKS))}
                isValid={
                  get(touched, this.withNamespace(SNACKS)) &&
                  !get(errors, this.withNamespace(SNACKS))
                }
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, this.withNamespace(SNACKS))}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
      </>
    );
  }
}

export default BookingConsumptionsSubForm;
