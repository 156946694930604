import config from "react-global-configuration";

import { CurrentuserService } from "@/_services";
import { history, handleResponse, authHeaders, defaultHeaders } from "@/_utils";

function login(username, password) {
  const apiurl = config.get("API_BASE_URL") + "/auth/authentication";
  const requestOptions = {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify({ username, password }),
    mode: "cors",
    credentials: "include",
  };

  return fetch(apiurl, requestOptions)
    .then(handleResponse)
    .then((data) => {
      for (const key in data) {
        if (key.includes("csrf")) {
          localStorage.setItem(key, data[key]);
        }
      }
      const role = data["role"];
      localStorage.setItem("username", username);
      localStorage.setItem("role", role);
      CurrentuserService.setCurrentuser(username, role);
    });
}

function logout() {
  const apiurl = config.get("API_BASE_URL") + "/auth/authentication";
  const requestOptions = {
    method: "DELETE",
    headers: defaultHeaders,
    mode: "cors",
    credentials: "include",
  };

  fetch(apiurl, requestOptions).then(handleResponse);

  localStorage.removeItem("username");
  localStorage.removeItem("role");
  localStorage.removeItem("access_csrf_token");
  localStorage.removeItem("access_csrf_header");
  localStorage.removeItem("refresh_csrf_token");
  localStorage.removeItem("refresh_csrf_header");

  CurrentuserService.clearCurrentuser();

  history.push("/login");
}

async function refresh() {
  const apiurl = config.get("API_BASE_URL") + "/auth/authentication";
  const requestOptions = {
    method: "PUT",
    headers: authHeaders(),
    mode: "cors",
    credentials: "include",
  };

  return fetch(apiurl, requestOptions)
    .then(handleResponse)
    .then((data) => {
      for (const key in data) {
        localStorage.setItem(key, data[key]);
      }
    });
}

export const AuthenticationService = {
  login,
  logout,
  refresh,
};
