import {
  DRINKS_NONALCOHOLIC,
  DRINKS_ALCOHOLIC,
  SNACKS,
} from "./fieldNames";

export default {
  [DRINKS_NONALCOHOLIC]: 0,
  [DRINKS_ALCOHOLIC]: 0,
  [SNACKS]: 0,
};
