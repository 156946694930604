const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};

const authHeaders = () => {
  const username = localStorage.getItem("username");
  if (username) {
    return Object.assign({}, defaultHeaders, {
      [localStorage.getItem("access_csrf_header")]: localStorage.getItem(
        "access_csrf_token"
      ),
      [localStorage.getItem("refresh_csrf_header")]: localStorage.getItem(
        "refresh_csrf_token"
      ),
    });
  } else {
    return defaultHeaders;
  }
};

export { authHeaders, defaultHeaders };
