import React from "react";

import NumberFormat from "react-number-format";

export function formatAmount(amount) {
  return (
    <NumberFormat
      value={parseFloat(amount)}
      displayType="text"
      decimalScale="2"
      fixedDecimalScale={true}
      prefix="€&nbsp;"
    />
  );
}
