import config from "react-global-configuration";
import moment from "moment";

import { authHeaders, genID, handleResponse, fetchWithRefresh } from "@/_utils";

function getAllForToday() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const apiurl = config.get("API_BASE_URL") + "/pos/bookingstoday/" + timezone;
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

function getAllForDate(date) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const apiurl =
    config.get("API_BASE_URL") +
    "/pos/bookings/" +
    moment(date).format("YYYY-MM-DD") +
    "/" +
    timezone;
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

function create(data) {
  const bookingid = genID(32);
  const apiurl = config.get("API_BASE_URL") + "/pos/booking/" + bookingid;
  const requestOptions = {
    method: "POST",
    headers: authHeaders(),
    body: JSON.stringify(data),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

function update(bookingId, patch) {
  function setFields(fieldlist, parent, object) {
    /* Recursive function populating a set with fieldnames of a patch (nested
     * dicts and arrays). Uses a dot-separator for nested keys.
     */
    if (typeof object !== "object" || object === null) {
      fieldlist.add(parent);
    } else if (Array.isArray(object)) {
      object.forEach((object, index) => {
        setFields(fieldlist, parent, object);
      });
    } else {
      Object.entries(object).forEach(([key, value]) => {
        let newparent = key;
        if (parent) {
          newparent = parent + "." + key;
        }
        setFields(fieldlist, newparent, value);
      });
    }
  }

  let myFields = new Set();
  setFields(myFields, "", patch);

  const fields = new URLSearchParams();
  fields.set("fields", [...myFields]);
  const apiurl =
    config.get("API_BASE_URL") +
    "/pos/booking/" +
    bookingId +
    "?" +
    fields.toString();
  const requestOptions = {
    method: "PATCH",
    headers: authHeaders(),
    body: JSON.stringify(patch),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

function cancel(bookingid) {
  const apiurl = config.get("API_BASE_URL") + "/pos/booking/" + bookingid;
  const requestOptions = {
    method: "DELETE",
    headers: authHeaders(),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

export const BookingService = { getAllForToday, getAllForDate, update, create, cancel };
