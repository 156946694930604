import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import UpdateBookingForm from "@/_forms/UpdateBookingForm";

class UpdateBookingModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.props.onHide;
  }

  render() {
    return (
      <Modal
        show={this.props.bookingcard != null}
        onHide={this.handleCancel}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="lg"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Update booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateBookingForm onSave={this.props.onSave} bookingcard={this.props.bookingcard} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UpdateBookingModal;
