/* eslint-disable no-template-curly-in-string */
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";
import { Formik } from "formik";
import { GiPayMoney } from "react-icons/gi";

import { PaymentMethodService } from "@/_services";

class RegisterBookingPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: [{ method: "Loading payment methods" }],
    };

    this.booking = this.props.bookingcard.props.booking;
    this.handleSave = this.props.onAdd;

    this.initialValues = {
      method: "",
      amount: "",
    };

    this.validationSchema = Yup.object().shape({
      method: Yup.string().ensure().required("Please select a payment method"),
      amount: Yup.number()
        .typeError("Must be a ${type}")
        .required("Required")
        .moreThan(0, "Must be more than zero"),
    });

    this.innerForm = ({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
    }) => (
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Col>
            <Form.Group controlId="method">
              <Form.Control
                as="select"
                name="method"
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  touched.method && errors.method ? "is-invalid" : null
                }
                value={values.method}
                isValid={touched.method && !errors.method}
                custom
              >
                <option key="" value="" hidden>
                  Select payment method
                </option>
                {this.state.paymentMethods.map((paymentMethod) => (
                  <option
                    key={paymentMethod.method}
                    value={paymentMethod.method}
                    disabled={
                      paymentMethod.method
                        .toLowerCase()
                        .startsWith("loading") ||
                      paymentMethod.method.toLowerCase().startsWith("error")
                    }
                  >
                    {paymentMethod.method.charAt(0).toUpperCase() +
                      paymentMethod.method.toLowerCase().slice(1)}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.method}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="amount">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>€</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="amount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched.amount && errors.amount ? "is-invalid" : null
                  }
                  value={values.amount}
                  isValid={touched.amount && !errors.amount}
                  placeholder="Payment amount"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.amount}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="addPayment">
              <Button type="submit" variant="success">
                <GiPayMoney /> Add
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    );
  }

  fetchController = new window.AbortController();

  /**
   * Request the payment methods and populate the payment methods dropdown with
   * them.
   */
  populatePaymentMethods() {
    const resolved = (paymentMethods) => {
      if (paymentMethods.length === 0) {
        paymentMethods = [{ method: "error: No payment methods found." }];
      }
      paymentMethods.sort((a, b) =>
        a.method.localeCompare(b.method, "nl", { numeric: true })
      );
      this.setState({ paymentMethods: paymentMethods });
    };
    const rejected = (error) => {
      console.error(error);
      this.setState({
        paymentMethods: [{ method: "error:" + error.message }],
      });
    };
    PaymentMethodService.getAll().then(resolved, rejected);
  }

  componentDidMount() {
    this.populatePaymentMethods();
  }

  componentWillUnmount() {
    this.fetchController.abort();
  }

  render() {
    return (
      <Formik
        component={this.innerForm}
        initialValues={this.initialValues}
        onSubmit={this.handleSave}
        validationSchema={this.validationSchema}
      />
    );
  }
}

export default RegisterBookingPaymentForm;
