import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class CancelBookingModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.props.onHide;
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCancel}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="lg"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Cancel booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          Are you sure you want to cancel this booking?
          </p>
          <p>
          This cannot be undone and is usually only used when a group didn't show up for their booking.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.props.onSave(this.props.bookingid)}>
            Yes
          </Button>
          <Button variant="secondary" onClick={this.handleCancel}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CancelBookingModal;
