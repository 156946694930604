import * as Yup from "yup";
import {
  CAMPAIGN_NAME,
  CUSTOMER_NAME,
  SERVICE_ID,
  VARIATION_ID,
} from "./fieldNames";

export default Yup.object().shape({
  [CAMPAIGN_NAME]: Yup.string(),
  [CUSTOMER_NAME]: Yup.string().required("Required"),
  [SERVICE_ID]: Yup.string().required("Required"),
  [VARIATION_ID]: Yup.number().required("Required"),
});
