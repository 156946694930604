// ROLE BASED ACCESS CONTROL RULES

const gamemaster_permissions = [
  "bookings:cancel",
  "bookings:create",
  "bookings:list",
  "bookings:patch",
  "home:visit",
];

const admin_permissions = gamemaster_permissions.concat([
  "bookings:choosedate",
  "bookingsoverview:visit",
  "paymentsoverview:visit",
  "users:list",
]);

const superadmin_permissions = admin_permissions.concat([]);

const rules = {
  gamemaster: gamemaster_permissions,
  admin: admin_permissions,
  superadmin: superadmin_permissions,
};

export default rules;
