import config from "react-global-configuration";

import { authHeaders, handleResponse, fetchWithRefresh } from "@/_utils";

function getAllForMonth(year, month) {
  const apiurl =
    config.get("API_BASE_URL") + "/admin/payments/" + year + "/" + month;
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

export const PaymentsOverviewService = { getAllForMonth };
