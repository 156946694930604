import * as Yup from "yup";
import {
  DRINKS_NONALCOHOLIC,
  DRINKS_ALCOHOLIC,
  SNACKS,
} from "./fieldNames";

export default Yup.object().shape({
  [DRINKS_NONALCOHOLIC]: Yup.number()
    .integer("Must be in integer")
    .typeError("Must be a number")
    .required("Required")
    .min(0, "Cannot be negative"),
  [DRINKS_ALCOHOLIC]: Yup.number()
    .integer("Must be in integer")
    .typeError("Must be a number")
    .required("Required")
    .min(0, "Cannot be negative"),
  [SNACKS]: Yup.number()
    .integer("Must be in integer")
    .typeError("Must be a number")
    .required("Required")
    .min(0, "Cannot be negative"),
});
