import React, { useState, useMemo } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BTable from "react-bootstrap/Table";

import moment from "moment";
import Moment from "react-moment";

import { useTable, useFilters, useResizeColumns, useSortBy } from "react-table";
import { CSVLink } from "react-csv";

function PaymentsOverviewTable({ payments, year, month }) {
  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (event) => {
    const value = event.target.value || undefined;
    setFilter("methodName", value);
    setFilterInput(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "_id",
      },
      {
        Header: "Amount",
        accessor: (originalRow, rowIndex) =>
          originalRow.amount.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        id: "amount",
      },
      {
        Header: "Method",
        accessor: "methodName",
      },
      {
        Header: "Gamemaster",
        accessor: "gamemaster",
      },
      {
        Header: "Datetime",
        accessor: (originalRow, rowIndex) =>
          moment
            .utc(originalRow.updated)
            .local()
            .format("ddd, Do MMM YYYY, H:mm"),
        id: "updated",
      },
    ],
    []
  );

  const data = useMemo(() => payments, [payments]);

  const getCellProps = (cell) => {
    if (cell.column.id === "amount") {
      return { className: "currency" };
    }
    return {};
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable({ columns, data }, useFilters, useSortBy, useResizeColumns);

  return (
    <>
      <Row className="justify-content-end align-items-center mb-2">
        <Col className="col-3">
          <input
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Filter..."}
          />
        </Col>
        <Col className="col-2">
          <CSVLink
            data={data}
            className="btn btn-primary"
            filename={
              "simplepos-payments_" +
              year +
              "-" +
              String(month).padStart(2, "0") +
              ".csv"
            }
          >
            Download me
          </CSVLink>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <div className="table-responsive">
            <BTable striped hover size="sm" id="payments" {...getTableProps()}>
              <caption>
                List of payments registered in{" "}
                <Moment interval={0} format="MMMM YYYY" local>
                  {year + "-" + month}
                </Moment>
              </caption>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={
                          column.isSorted
                            ? column.isSortedDesc
                              ? "sort-desc"
                              : "sort-asc"
                            : ""
                        }
                        scope="col"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  const idcell = row.cells.shift();
                  return (
                    <tr {...row.getRowProps()}>
                      <th scope="row" {...idcell.getCellProps()}>
                        {idcell.render("Cell")}
                      </th>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps([getCellProps(cell)])}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </BTable>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default PaymentsOverviewTable;
