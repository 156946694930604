import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Moment from "react-moment";

import {
  FaBeer,
  FaCandyCane,
  FaClock,
  FaPhone,
  FaTicketAlt,
} from "react-icons/fa";
import { BsFillPeopleFill, BsInfoCircle } from "react-icons/bs";
import { MdEmail, MdRoomService, MdLocalDrink } from "react-icons/md";
import { GrStatusUnknown } from "react-icons/gr";

import { Can, formatAmount } from "@/_utils";

class BookingCard extends React.Component {
  bookingInfoCircle(comment) {
    const popover = (
      <Popover id="popover-bookingcomment">
        <Popover.Title as="h3">Comment</Popover.Title>
        <Popover.Content>{this.props.booking.comment}</Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger placement="top" overlay={popover}>
        <BsInfoCircle />
      </OverlayTrigger>
    );
  }

  customerInfo() {
    const popover = (
      <Popover id="popover-customerinfo">
        <Popover.Title as="h3">{this.props.booking.customerName}</Popover.Title>
        <Popover.Content>
          <FaPhone className="pr-1" />{" "}
          {this.props.booking.customerPhonenumber ? (
            <a href={"tel:" + this.props.booking.customerPhonenumber}>
              {this.props.booking.customerPhonenumber}
            </a>
          ) : (
            "no phone number :-("
          )}
          <br />
          <MdEmail className="pr-1" />{" "}
          {this.props.booking.customerEmailaddress ? (
            <a href={"mailto:" + this.props.booking.customerEmailaddress}>
              {this.props.booking.customerEmailaddress}
            </a>
          ) : (
            "no email address :-("
          )}
          <br />
          <FaTicketAlt className="pr-1" /> {this.props.booking.code}
        </Popover.Content>
      </Popover>
    );
    return (
      <OverlayTrigger placement="top" overlay={popover}>
        <span>{this.props.booking.customerName}</span>
      </OverlayTrigger>
    );
  }

  paymentInfo() {
    const paymentList = (() => {
      var payments = this.props.booking.payments;
      payments.sort((a, b) => a.method.localeCompare(b.method, "nl"));
      return payments.map((payment, index) => {
        return (
          <React.Fragment key={payment.method + "_" + index}>
            <dt className="col-sm-6">{payment.method}</dt>
            <dd className="col-sm-6">
              <span className="float-right">
                {formatAmount(payment.amount)}
              </span>
            </dd>
          </React.Fragment>
        );
      });
    })();

    const popover = (
      <Popover id="popover-paymentinfo">
        <Popover.Title as="h3">Payment info</Popover.Title>
        <Popover.Content>
          {this.props.booking.totalPaid === this.props.booking.totalPrice ? (
            <dl className="row">{paymentList}</dl>
          ) : (
            "Unpaid"
          )}
        </Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger placement="top" overlay={popover}>
        <span className="float-right">
          <strong>{formatAmount(this.props.booking.totalPrice)}</strong>
        </span>
      </OverlayTrigger>
    );
  }

  render() {
    const paid = this.props.booking.totalPrice === this.props.booking.totalPaid;

    var border = "primary";
    if (["CANCELED", "FORCE_CANCELED"].includes(this.props.booking.status)) {
      border = "secondary";
    } else if (paid) {
      border = "success";
    } else {
      border = "danger";
    }

    const consumptionlist = (() => {
      var consumptions = this.props.booking.consumptions;

      if (consumptions === null) {
        return <></>;
      }

      const icons = new Proxy(
        {
          drinksNonalcoholic: <MdLocalDrink />,
          drinksAlcoholic: <FaBeer />,
          snacks: <FaCandyCane />,
        },
        {
          get: (target, prop, receiver) =>
            prop in target ? target[prop] : <GrStatusUnknown />,
        }
      );

      consumptions.sort((a, b) => a.category.localeCompare(b.category, "nl"));
      return consumptions.map((consumption) => {
        return (
          <React.Fragment key={consumption.category}>
            <dt className="col-sm-2">{icons[consumption.category]}</dt>
            <dd className="col-sm-10">
              {consumption.amount} &times; {formatAmount(consumption.tariff)}
              <span className="float-right">
                {formatAmount(consumption.amount * consumption.tariff)}
              </span>
            </dd>
          </React.Fragment>
        );
      });
    })();

    const cardbuttons = (() => {
      if (
        paid ||
        ["CANCELED", "FORCE_CANCELED"].includes(this.props.booking.status)
      ) {
        return;
      }

      var buttons = [];
      buttons.push(
        <Button
          key="update"
          variant="primary"
          onClick={() => this.props.onStartUpdate(this)}
        >
          Update
        </Button>
      );

      if (
        this.props.booking.consumptions !== null &&
        this.props.booking.consumptions.length > 0
      ) {
        buttons.push(
          <Button
            key="registerpayment"
            variant="success"
            onClick={() => this.props.onStartRegisterPayments(this)}
          >
            Payment
          </Button>
        );
      } else {
        buttons.push(
          <Button
            key="cancelbooking"
            variant="danger"
            onClick={() => this.props.onCancelBooking(this.props.booking._id)}
          >
            Cancel
          </Button>
        );
      }

      return (
        <Can
          perform="bookings:patch"
          yes={() => (
            <Card.Footer className="text-center">
              <ButtonGroup>{buttons}</ButtonGroup>
            </Card.Footer>
          )}
        />
      );
    })();

    return (
      <Card border={border}>
        <Card.Img variant="top" src={this.props.booking.service.imgurl} />
        <Card.Body>
          <Card.Title>
            {this.customerInfo()}{" "}
            {this.props.booking.comment && this.bookingInfoCircle()}
          </Card.Title>
          <dl className="row">
            <dt className="col-sm-2">
              <MdRoomService />
            </dt>
            <dd className="col-sm-10">{this.props.booking.service.title}</dd>
            <dt className="col-sm-2">
              <FaClock />
            </dt>
            <dd className="col-sm-10">
              <Moment interval={0} format="HH:mm" utc local>
                {this.props.booking.starttime}
              </Moment>
            </dd>
            <dt className="col-sm-2">
              <BsFillPeopleFill />
            </dt>
            <dd className="col-sm-10">
              {!["CANCELED", "FORCE_CANCELED"].includes(
                this.props.booking.status
              ) ? (
                this.props.booking.serviceVariation.title
              ) : (
                <strong>{this.props.booking.status}</strong>
              )}
              {!["CANCELED", "FORCE_CANCELED"].includes(
                this.props.booking.status
              ) && (
                <span className="float-right">
                  {formatAmount(this.props.booking.serviceVariation.price)}
                </span>
              )}
            </dd>
            {consumptionlist}
          </dl>
          {!["CANCELED", "FORCE_CANCELED"].includes(
            this.props.booking.status
          ) && (
            <>
              <hr />
              <Card.Text>Total: {this.paymentInfo()}</Card.Text>
            </>
          )}
        </Card.Body>
        {cardbuttons}
      </Card>
    );
  }
}

export default BookingCard;
