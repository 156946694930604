import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import moment from "moment";
import Moment from "react-moment";
import DatePicker from "react-date-picker";

import BookingCard from "@/_components/BookingCard";
import DismissibleAlert from "@/_components/DismissibleAlert";

import CreateBookingModal from "@/_components/_modals/CreateBookingModal";
import RegisterBookingPaymentModal from "@/_components/_modals/RegisterBookingPaymentModal";
import UpdateBookingModal from "@/_components/_modals/UpdateBookingModal";
import CancelBookingModal from "@/_components/_modals/CancelBookingModal";

import { Can, genID, levelToVariant } from "@/_utils";
import { BookingService } from "@/_services";

class SimplePOS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      alerts: [
        {
          variant: "info",
          key: "loading_bookings",
          message: "Loading bookings for today...",
        },
      ],
      updating: null,
      paying: null,
      cancelling: null,
      creating: false,
      date: new Date(),
    };
  }

  async componentDidMount() {
    const resolved = (bookings) => {
      bookings.sort((a, b) =>
        a.starttime.localeCompare(b.starttime, "nl", { numeric: true })
      );
      this.setState({ bookings: bookings });
      if (bookings.length === 0) {
        const alert = {
          variant: "info",
          message: "No bookings found for today.",
          key: "info_loading_bookings_" + this.state.alerts.length,
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    const rejected = (error) => {
      console.error(error);
      if ("exception" in error) {
        const alert = {
          variant: levelToVariant[error.level],
          message: error.message,
          key: "exception_loading_bookings_" + this.state.alerts.length,
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    await BookingService.getAllForToday().then(resolved, rejected);

    this.setState({
      alerts: this.state.alerts.filter((alert) => {
        return !alert.key.startsWith("loading_bookings");
      }),
    });
  }

  cancelCreateBooking = () => {
    this.setState({ creating: false });
  };

  startCreateBooking = () => {
    this.setState({ creating: true });
  };

  finishCreateBooking = (bookingData, bag) => {
    bookingData["code"] = genID(3).toUpperCase() + "-" + genID(6).toUpperCase();
    bookingData["status"] = "MANUAL";

    const resolved = (booking) => {
      this.setState({ bookings: this.state.bookings.concat(booking) });
    };
    const rejected = (error) => {
      if ("exception" in error) {
        const alert = {
          variant: levelToVariant[error.level],
          message: error.message,
          key: "exception_creating_booking",
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    BookingService.create(bookingData).then(resolved, rejected);

    bag.setSubmitting(false);
    this.setState({ creating: false });
  };

  cancelUpdateBooking = () => {
    this.setState({ updating: null });
  };

  startUpdateBooking = (bookingcard) => {
    this.setState({ updating: bookingcard });
  };

  finishUpdateBooking = (bookingId, bookingPatch, bag) => {
    // Patch the booking from the bookingcard with some information from the
    // bookingcard

    const resolved = (booking) => {
      const updatedBookingIndex = this.state.bookings.findIndex((bk) => {
        return bk._id === booking._id;
      });
      const bookings = this.state.bookings.slice();
      bookings.splice(updatedBookingIndex, 1, booking);
      this.setState({ bookings: bookings });
    };
    const rejected = (error) => {
      if ("exception" in error) {
        const alert = {
          variant: levelToVariant[error.level],
          message: error.message,
          key: "exception_updating_booking",
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    BookingService.update(bookingId, bookingPatch).then(resolved, rejected);

    bag.setSubmitting(false);
    this.setState({ updating: null });
  };

  cancelRegisterBookingPayments = () => {
    this.setState({ paying: null });
  };

  startRegisterBookingPayments = (bookingcard) => {
    this.setState({ paying: bookingcard });
  };

  finishRegisterBookingPayments = (bookingID, payments) => {
    // Patch the booking with the given id with payment data.
    const bookingpatch = {
      payments: payments,
    };

    const resolved = (booking) => {
      const updatedBookingIndex = this.state.bookings.findIndex((bk) => {
        return bk._id === booking._id;
      });
      const bookings = this.state.bookings.slice();
      bookings.splice(updatedBookingIndex, 1, booking);
      this.setState({ bookings: bookings });
    };
    const rejected = (error) => {
      if ("exception" in error) {
        const alert = {
          variant: levelToVariant[error.level],
          message: error.message,
          key: "exception_updating_booking",
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    BookingService.update(bookingID, bookingpatch).then(resolved, rejected);

    this.setState({ paying: null });
  };

  cancelCancelBooking = () => {
    this.setState({ cancelling: null });
  };

  startCancelBooking = (bookingID) => {
    this.setState({ cancelling: bookingID });
  };

  finishCancelBooking = (bookingID) => {
    const resolved = (booking) => {
      const updatedBookingIndex = this.state.bookings.findIndex((bk) => {
        return bk._id === booking._id;
      });
      const bookings = this.state.bookings.slice();
      bookings.splice(updatedBookingIndex, 1, booking);
      this.setState({ bookings: bookings });
    };

    const rejected = (error) => {
      console.error(error);
      if ("exception" in error) {
        const alert = {
          variant: levelToVariant[error.level],
          message: error.message,
          key: "exception_canceling_booking_" + this.state.alerts.length,
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };

    BookingService.cancel(bookingID).then(resolved, rejected);

    this.setState({ cancelling: null });
  };

  dateChanged = async (date) => {
    this.setState({
      alerts: this.state.alerts.filter((alert) => {
        return !alert.key.startsWith("info_loading_bookings");
      }),
    });
    this.setState({
      alerts: this.state.alerts.concat({
        variant: "info",
        key: "loading_bookings",
        message:
          "Loading bookings for " +
          moment(date).format("dddd, Do MMMM YYYY") +
          "...",
      }),
    });

    const resolved = (bookings) => {
      bookings.sort((a, b) =>
        a.starttime.localeCompare(b.starttime, "nl", { numeric: true })
      );
      this.setState({ bookings: bookings });
      if (bookings.length === 0) {
        const alert = {
          variant: "info",
          message:
            "No bookings found for " +
            moment(date).format("dddd, Do MMMM YYYY") +
            ".",
          key: "info_loading_bookings_" + this.state.alerts.length,
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    const rejected = (error) => {
      console.error(error);
      if ("exception" in error) {
        const alert = {
          variant: levelToVariant[error.level],
          message: error.message,
          key: "exception_loading_bookings_" + this.state.alerts.length,
        };
        this.setState({ alerts: this.state.alerts.concat(alert) });
      }
    };
    await BookingService.getAllForDate(date).then(resolved, rejected);

    this.setState({ date: date });
    this.setState({
      alerts: this.state.alerts.filter((alert) => {
        return !alert.key.startsWith("loading_bookings");
      }),
    });
  };

  render() {
    const alerts = this.state.alerts.map((alert, index) => {
      return (
        <DismissibleAlert
          key={alert.key + "_" + index}
          variant={alert.variant}
          dismissible
        >
          {alert.message.startsWith("Loading") && (
            <>
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">{alert.message}</span>
              </Spinner>{" "}
            </>
          )}
          {alert.message}
        </DismissibleAlert>
      );
    });

    const bookingcards = (() => {
      const cards = this.state.bookings.map((booking) => {
        return (
          <Col className="mb-4" key={booking._id}>
            <BookingCard
              booking={booking}
              onStartUpdate={this.startUpdateBooking}
              onStartRegisterPayments={this.startRegisterBookingPayments}
              onCancelBooking={this.startCancelBooking}
            />
          </Col>
        );
      });
      return (
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4">
          {cards}
        </Row>
      );
    })();

    return (
      <>
        <Can
          perform="bookings:create"
          yes={() => (
            <CreateBookingModal
              show={this.state.creating}
              onHide={this.cancelCreateBooking}
              onSave={this.finishCreateBooking}
              date={this.state.date}
            />
          )}
        />
        <Can
          perform="bookings:patch"
          yes={() => (
            <>
              <UpdateBookingModal
                bookingcard={this.state.updating}
                onHide={this.cancelUpdateBooking}
                onSave={this.finishUpdateBooking}
              />
              <RegisterBookingPaymentModal
                bookingcard={this.state.paying}
                onHide={this.cancelRegisterBookingPayments}
                onSave={this.finishRegisterBookingPayments}
              />
            </>
          )}
        />
        <Can
          perform="bookings:cancel"
          yes={() => (
            <CancelBookingModal
              show={this.state.cancelling !== null}
              onHide={this.cancelCancelBooking}
              onSave={this.finishCancelBooking}
              bookingid={this.state.cancelling}
            />
          )}
        />

        <h2>
          Bookings for{" "}
          <Moment interval={0} format="dddd, Do MMMM YYYY" local>
            {this.state.date}
          </Moment>
        </h2>
        <Can
          perform="bookings:choosedate"
          yes={() => (
            <div className="mb-1">
              <DatePicker
                value={this.state.date}
                format="y-MM-dd"
                minDetail="year"
                onChange={this.dateChanged}
              />
            </div>
          )}
        />
        <Row>
          <Col>{alerts}</Col>
        </Row>

        <Can
          perform="bookings:create"
          yes={() => (
            <Row>
              <Col>
                <Button variant="secondary" onClick={this.startCreateBooking}>
                  Add new booking
                </Button>
              </Col>
            </Row>
          )}
        />
        <Row className="mt-2">
          <Col>{bookingcards}</Col>
        </Row>
      </>
    );
  }
}

export { SimplePOS };
