import React from "react";

import { Formik } from "formik";
import get from "lodash/get";
import moment from "moment";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { SlotService } from "@/_services";
import BookingPatchBasicsSubForm from "@/_forms/_subforms/BookingPatchBasics";
import BookingConsumptionsSubForm from "@/_forms/_subforms/BookingConsumptions";
import validationSchema from "./validationSchema";
import defaultValues from "./defaultValues";
import {
  CAMPAIGN_NAME,
  COMMENT,
  CONSUMPTIONS,
  CUSTOMER_NAME,
  DRINKS_ALCOHOLIC,
  DRINKS_NONALCOHOLIC,
  EMAILADDRESS,
  PATCHBASICS,
  PHONENUMBER,
  SERVICE_ID,
  SNACKS,
  STARTTIME,
  VARIATION_ID,
} from "./fieldNames";

class CreateBookingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { slots: [{ value: "loading", label: "Loading slots..." }] };

    this.onSubmit = (formdata, bag) => {
      const consumptions = [DRINKS_NONALCOHOLIC, DRINKS_ALCOHOLIC, SNACKS].map(
        (category) => {
          return {
            category: category,
            amount: formdata[CONSUMPTIONS][category],
          };
        }
      );
      const bookingdata = {
        [SERVICE_ID]: formdata[PATCHBASICS][SERVICE_ID],
        [VARIATION_ID]: formdata[PATCHBASICS][VARIATION_ID],
        consumptions: consumptions,
        [CAMPAIGN_NAME]: formdata[PATCHBASICS][CAMPAIGN_NAME] || null,
        [CUSTOMER_NAME]: formdata[PATCHBASICS][CUSTOMER_NAME],
        [STARTTIME]: formdata[STARTTIME],
        [COMMENT]: formdata[COMMENT],
        [EMAILADDRESS]: formdata[EMAILADDRESS],
        [PHONENUMBER]: formdata[PHONENUMBER],
      };
      this.props.onSave(bookingdata, bag);
    };

    this.innerForm = ({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
    }) => (
      <Form onSubmit={handleSubmit}>
        <BookingPatchBasicsSubForm
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          namespace={PATCHBASICS}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          onServiceSelected={this.serviceSelected}
        />

        <Form.Row>
          <Col>
            <Form.Group controlId={STARTTIME}>
              <Form.Label>Start time</Form.Label>
              <Form.Control
                as="select"
                name={STARTTIME}
                onBlur={handleBlur}
                onChange={handleChange}
                className={
                  get(touched, STARTTIME) && get(errors, STARTTIME)
                    ? "is-invalid"
                    : null
                }
                value={get(values, STARTTIME)}
                isValid={get(touched, STARTTIME) && !get(errors, STARTTIME)}
                custom
              >
                <option key="" hidden>
                  Select start time
                </option>
                {this.state.slots.map((slot) => (
                  <option
                    key={slot.value}
                    value={slot.value}
                    disabled={
                      slot.value.startsWith("loading") ||
                      slot.value.startsWith("error")
                    }
                  >
                    {slot.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {get(errors, STARTTIME)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId={COMMENT}>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                name={COMMENT}
                placeholder="Comment"
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  get(touched, COMMENT) && get(errors, COMMENT)
                    ? "is-invalid"
                    : null
                }
                value={get(values, COMMENT)}
                isValid={get(touched, COMMENT) && !get(errors, COMMENT)}
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, COMMENT)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Group controlId={EMAILADDRESS}>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name={EMAILADDRESS}
                placeholder="Email address"
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  get(touched, EMAILADDRESS) && get(errors, EMAILADDRESS)
                    ? "is-invalid"
                    : null
                }
                value={get(values, EMAILADDRESS)}
                isValid={
                  get(touched, EMAILADDRESS) && !get(errors, EMAILADDRESS)
                }
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, EMAILADDRESS)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId={PHONENUMBER}>
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                type="text"
                name={PHONENUMBER}
                placeholder="Phone number"
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  get(touched, PHONENUMBER) && get(errors, PHONENUMBER)
                    ? "is-invalid"
                    : null
                }
                value={get(values, PHONENUMBER)}
                isValid={get(touched, PHONENUMBER) && !get(errors, PHONENUMBER)}
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, PHONENUMBER)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <BookingConsumptionsSubForm
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          namespace={CONSUMPTIONS}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />

        <Button type="submit">Save</Button>
      </Form>
    );
  }

  /**
   * Request the slots for a service from the API and populate the
   * slot dropdown with them.
   */
  populateSlots(serviceID) {
    const resolved = (slots) => {
      if (slots.length === 0) {
        this.setState({
          slots: [{ value: "error", label: "No available slots found." }],
        });
      } else {
        slots.sort((a, b) =>
          a.starttime.localeCompare(b.starttime, "nl", { numeric: true })
        );
        this.setState({
          slots: slots.map((slot) => {
            var label = slot.starttime;
            if (!isNaN(new Date(slot.starttime))) {
              // checks if slot.starttime is a valid datetime
              label = moment(slot.starttime).format("HH:mm");
            }
            return { value: slot.starttime, label: label };
          }),
        });
      }
    };
    const rejected = (error) => {
      console.error(error);
      this.setState({
        slots: [{ value: "error", label: error.message }],
      });
    };
    SlotService.getAllForDay(serviceID, this.props.date).then(resolved, rejected);
  }

  serviceSelected = (serviceid, setFieldValue) => {
    this.populateSlots(serviceid);
    setFieldValue(STARTTIME, "");
  };

  render() {
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={defaultValues}
        validationSchema={validationSchema}
      >
        {this.innerForm}
      </Formik>
    );
  }
}

export default CreateBookingForm;
