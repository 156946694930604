import React from "react";

import { Formik, Form } from "formik";

import Button from "react-bootstrap/Button";

import BookingPatchBasicsSubForm from "@/_forms/_subforms/BookingPatchBasics";
import BookingConsumptionsSubForm from "@/_forms/_subforms/BookingConsumptions";
import validationSchema from "./validationSchema";
import defaultValues from "./defaultValues";
import {
  CONSUMPTIONS,
  CAMPAIGN_NAME,
  CUSTOMER_NAME,
  DRINKS_ALCOHOLIC,
  DRINKS_NONALCOHOLIC,
  PATCHBASICS,
  SERVICE_ID,
  SNACKS,
  VARIATION_ID,
} from "./fieldNames";

class UpdateBookingForm extends React.Component {
  constructor(props) {
    super(props);

    this.booking = this.props.bookingcard.props.booking;

    this.onSubmit = (formdata, bag) => {
      const consumptions = [DRINKS_NONALCOHOLIC, DRINKS_ALCOHOLIC, SNACKS].map(
        (category) => {
          return {
            category: category,
            amount: formdata[CONSUMPTIONS][category],
          };
        }
      );
      const bookingpatch = {
        [SERVICE_ID]: formdata[PATCHBASICS][SERVICE_ID],
        [VARIATION_ID]: formdata[PATCHBASICS][VARIATION_ID],
        consumptions: consumptions,
        [CUSTOMER_NAME]: formdata[PATCHBASICS][CUSTOMER_NAME],
        [CAMPAIGN_NAME]: formdata[PATCHBASICS][CAMPAIGN_NAME],
      };
      this.props.onSave(formdata["_id"], bookingpatch, bag);
    };

    this.initialValues = this.prepareInitialValues(defaultValues, this.booking);

    this.innerForm = ({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
    }) => (
      <Form onSubmit={handleSubmit}>
        <BookingPatchBasicsSubForm
          booking={this.booking}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          namespace={PATCHBASICS}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />
        <BookingConsumptionsSubForm
          booking={this.booking}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          namespace={CONSUMPTIONS}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />
        <Button type="submit">Save</Button>
      </Form>
    );
  }

  prepareInitialValues(defaultValues, booking) {
    return {
      [PATCHBASICS]: Object.assign(
        {},
        defaultValues[PATCHBASICS],
        [CAMPAIGN_NAME, CUSTOMER_NAME, SERVICE_ID, VARIATION_ID].reduce(
          (bookingdata, key) => {
            bookingdata[key] = this.booking[key]
              ? this.booking[key]
              : defaultValues[PATCHBASICS][key];
            return bookingdata;
          },
          {}
        )
      ),
      [CONSUMPTIONS]: Object.assign(
        {},
        defaultValues[CONSUMPTIONS],
        [DRINKS_ALCOHOLIC, DRINKS_NONALCOHOLIC, SNACKS].reduce(
          (consumptiondata, key) => {
            for (const consumption of this.booking.consumptions) {
              if (consumption.category === key) {
                consumptiondata[key] = consumption.amount;
                break;
              }
            }
            return consumptiondata;
          },
          {}
        )
      ),
      _id: this.booking._id,
    };
  }

  render() {
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={this.initialValues}
        validationSchema={validationSchema}
      >
        {this.innerForm}
      </Formik>
    );
  }
}

export default UpdateBookingForm;
