import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { AuthenticationService } from "@/_services";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (AuthenticationService.tokenValue) {
      this.props.history.push("/");
    }

    this.initialValues = {
      username: "",
      password: "",
    };

    this.validationSchema = Yup.object().shape({
      username: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    });

    this.innerForm = ({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
    }) => (
      <Row className="justify-content-center">
        <Col xs="6">
          <Card>
            <Card.Body>
              <Card.Title>Please login to access SimplePOS</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    autoFocus
                    isValid={touched.username && !errors.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="username"
                    type="text"
                    value={values.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    isValid={touched.password && !errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="password"
                    type="password"
                    value={values.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit">Login</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  handleLogin = ({ username, password }, { setStatus, setSubmitting }) => {
    setStatus();
    AuthenticationService.login(username, password).then(
      () => {
        const { from } = this.props.location.state || {
          from: { pathname: "/pos" },
        };
        this.props.history.push(from);
      },
      (error) => {
        setSubmitting(false);
        setStatus(error);
      }
    );
  };

  render() {
    return (
      <Formik
        component={this.innerForm}
        initialValues={this.initialValues}
        onSubmit={this.handleLogin}
        validationSchema={this.validationSchema}
      />
    );
  }
}

export { LoginForm };
