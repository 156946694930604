export {
  CAMPAIGN_NAME,
  CUSTOMER_NAME,
  SERVICE_ID,
  VARIATION_ID,
} from "@/_forms/_subforms/BookingPatchBasics/fieldNames";

export {
  DRINKS_NONALCOHOLIC,
  DRINKS_ALCOHOLIC,
  SNACKS,
} from "@/_forms/_subforms/BookingConsumptions/fieldNames";

export const PATCHBASICS = "patchbasics";
export const CONSUMPTIONS = "consumptions";

export const STARTTIME = "starttime";
export const COMMENT = "comment";
export const EMAILADDRESS = "customerEmailaddress";
export const PHONENUMBER = "customerPhonenumber";
