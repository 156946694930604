import React, { useState, useMemo } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BTable from "react-bootstrap/Table";

import Moment from "react-moment";

import { useTable, useFilters, useResizeColumns, useSortBy } from "react-table";
import { CSVLink } from "react-csv";

function BookingsOverviewTable({ bookings, year, month }) {
  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (event) => {
    const value = event.target.value || undefined;
    setFilter("customerName", value);
    setFilterInput(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        Footer: "Totals:",
        accessor: "serviceDate",
      },
      {
        Header: "Start time",
        accessor: "serviceStarttime",
      },
      {
        Header: "Customer name",
        accessor: "customerName",
      },
      {
        Header: "Service",
        Footer: (info) => {
          const total = useMemo(() => info.rows.length, [info.rows]);
          return <>{total} bookings</>;
        },
        accessor: "service",
      },
      {
        Header: "Game",
        Footer: (info) => {
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.servicePrice) + sum,
                0
              ),
            [info.rows]
          );
          return total.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
        },
        accessor: (originalRow, rowIndex) =>
          originalRow.servicePrice.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        id: "servicePrice",
      },
      {
        Header: "Consumptions",
        Footer: (info) => {
          const total = useMemo(
            () =>
              info.rows
                .map((row) => {
                  const num = parseFloat(row.values.consumptionsPrice);
                  if (isNaN(num)) {
                    return 0;
                  } else {
                    return num;
                  }
                })
                .reduce(
                  (sum, val) => val + sum,
                  0
                ),
            [info.rows]
          );
          return total.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
        },
        accessor: (originalRow, rowIndex) => {
          var price = 0;
          [
            "drinksNonalcoholicPrice",
            "drinksAlcoholicPrice",
            "snacksPrice",
          ].forEach((category) => {
            if (category in originalRow) {
              price += originalRow[category];
            }
          });
          return price
            ? price.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : "";
        },
        id: "consumptionsPrice",
      },
      {
        Header: "Payment",
        Footer: (info) => {
          const total = useMemo(
            () =>
              info.rows
                .map((row) => {
                  const num = parseFloat(row.values.totalPayment);
                  if (isNaN(num)) {
                    return 0;
                  } else {
                    return num;
                  }
                })
                .reduce(
                  (sum, val) => val + sum,
                  0
                ),
            [info.rows]
          );
          return total.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
        },
        accessor: (originalRow, rowIndex) => {
          var totalPayment = 0;
          for (const [key, val] of Object.entries(originalRow)) {
            if (key.endsWith("Payment")) {
              totalPayment += val;
            }
          }
          return totalPayment
            ? totalPayment.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : "";
        },
        id: "totalPayment",
      },
      // {
      //   Header: "Gamemaster",
      //   accessor: "gamemaster",
      // },
    ],
    []
  );

  const data = useMemo(() => bookings, [bookings]);

  const getCellProps = (cell) => {
    if (cell.value === "") {
      return {};
    }
    return getColumnProps(cell.column);
  };

  const getColumnProps = (col) => {
    if (
      ["servicePrice", "consumptionsPrice", "totalPayment"].indexOf(col.id) > -1
    ) {
      return { className: "currency" };
    }
    return {};
  };

  const {
    footerGroups,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    setFilter,
  } = useTable({ columns, data }, useFilters, useSortBy, useResizeColumns);

  return (
    <>
      <Row className="justify-content-end align-items-center mb-2">
        <Col className="col-3">
          <input
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Filter..."}
          />
        </Col>
        <Col className="col-2">
          <CSVLink
            data={data}
            className="btn btn-primary"
            filename={
              "simplepos-bookings_" +
              year +
              "-" +
              String(month).padStart(2, "0") +
              ".csv"
            }
          >
            Download me
          </CSVLink>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <div className="table-responsive">
            <BTable striped hover size="sm" id="bookings" {...getTableProps()}>
              <caption>
                List of bookings handled in{" "}
                <Moment interval={0} format="MMMM YYYY" local>
                  {year + "-" + month}
                </Moment>
              </caption>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={
                          column.isSorted
                            ? column.isSortedDesc
                              ? "sort-desc"
                              : "sort-asc"
                            : ""
                        }
                        scope="col"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps([getCellProps(cell)])}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                {footerGroups.map((group) => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <td {...column.getFooterProps([getColumnProps(column)])}>
                        {column.render("Footer")}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </BTable>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default BookingsOverviewTable;
