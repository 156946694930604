import config from "react-global-configuration";
import moment from "moment";

import { authHeaders, handleResponse, fetchWithRefresh } from "@/_utils";

function getAllForToday(serviceID) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const apiurl =
    config.get("API_BASE_URL") + `/pos/slots/${serviceID}/${timezone}`;
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

function getAllForDay(serviceID, date) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const apiurl =
    config.get("API_BASE_URL") +
    "/pos/slots/" +
    serviceID +
    "/" +
    moment(date).format("YYYY-MM-DD") +
    "/" +
    timezone;
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
    credentials: "include",
  };

  return fetchWithRefresh(apiurl, requestOptions).then(handleResponse);
}

export const SlotService = { getAllForDay, getAllForToday };
