import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import get from "lodash/get";

import {
  CampaignService,
  ServiceService,
  ServiceVariationService,
} from "@/_services";

import {
  CAMPAIGN_NAME,
  CUSTOMER_NAME,
  SERVICE_ID,
  VARIATION_ID,
} from "./fieldNames";

class BookingPatchBasicsSubForm extends React.Component {
  constructor(props) {
    super(props);

    this.state =
      "booking" in this.props
        ? {
            services: [
              {
                _id: this.props.booking.service._id,
                title: this.props.booking.service.title,
              },
            ],
            variations: [
              {
                _id: this.props.booking.serviceVariation._id,
                title: this.props.booking.serviceVariation.title,
              },
            ],
            campaigns:
              this.props.booking.campaignName !== null
                ? [
                    {
                      name: this.props.booking.campaignName,
                    },
                  ]
                : [],
          }
        : {
            services: [],
            variations: [],
            campaigns: [],
          };
  }

  /**
   * Request the services from the API and populate the services dropdown with
   * them.
   */
  populateServices() {
    const resolved = (services) => {
      if (services.length === 0) {
        services = [{ _id: "error", title: "No services found." }];
      }
      services.sort((a, b) =>
        a.title.localeCompare(b.title, "nl", { numeric: true })
      );
      this.setState({ services: services });
    };
    const rejected = (error) => {
      console.error(error);
      this.setState({
        services: [{ _id: "error", title: error.message }],
      });
    };
    ServiceService.getAll().then(resolved, rejected);
  }

  /**
   * Request the variations for a service from the API and populate the
   * variations dropdown with them.
   */
  populateServiceVariations(serviceID) {
    const resolved = (servicevariations) => {
      if (servicevariations.length === 0) {
        servicevariations = [{ _id: "error", title: "No services found." }];
      }
      servicevariations.sort((a, b) =>
        a.title.localeCompare(b.title, "nl", { numeric: true })
      );
      this.setState({ variations: servicevariations });
    };
    const rejected = (error) => {
      console.error(error);
      this.setState({
        serviceVariations: [{ _id: "error", title: error.message }],
      });
    };
    ServiceVariationService.getAll(serviceID).then(resolved, rejected);
  }

  /**
   * Request the campaign from the API and populate the campaign dropdown with
   * them.
   */
  populateCampaigns() {
    const resolved = (campaigns) => {
      if (campaigns.length === 0) {
        campaigns = [{ name: "error: No campaigns found." }];
      }
      campaigns.sort((a, b) =>
        a.name.localeCompare(b.name, "nl", { numeric: true })
      );
      this.setState({ campaigns: campaigns });
    };
    const rejected = (error) => {
      console.error(error);
      this.setState({
        campaigns: [{ name: "error: " + error.message }],
      });
    };
    CampaignService.getAll().then(resolved, rejected);
  }

  serviceSelected = (e, handleChange, setFieldValue) => {
    handleChange(e);
    const serviceid = e.target.value;
    this.populateServiceVariations(serviceid);
    if (this.props.onServiceSelected) {
      this.props.onServiceSelected(serviceid, setFieldValue);
    }
    setFieldValue(this.withNamespace(VARIATION_ID), "");
  };

  componentDidMount() {
    this.populateServices();
    if ("booking" in this.props) {
      this.populateServiceVariations(this.props.booking.service._id);
    }
    this.populateCampaigns();
  }

  withNamespace(fieldName) {
    const namespace = this.props.namespace;
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  }

  render() {
    const {
      handleChange,
      handleBlur,
      setFieldValue,
      touched,
      errors,
      values,
    } = this.props;

    return (
      <>
        <Form.Row>
          <Col>
            <Form.Group controlId={this.withNamespace(CUSTOMER_NAME)}>
              <Form.Label>Customer name</Form.Label>
              <Form.Control
                type="text"
                name={this.withNamespace(CUSTOMER_NAME)}
                placeholder="Customer name"
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  get(touched, this.withNamespace(CUSTOMER_NAME)) &&
                  get(errors, this.withNamespace(CUSTOMER_NAME))
                    ? "is-invalid"
                    : null
                }
                value={get(values, this.withNamespace(CUSTOMER_NAME))}
                isValid={
                  get(touched, this.withNamespace(CUSTOMER_NAME)) &&
                  !get(errors, this.withNamespace(CUSTOMER_NAME))
                }
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, this.withNamespace(CUSTOMER_NAME))}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={this.withNamespace(CAMPAIGN_NAME)}>
              <Form.Label>Campaign</Form.Label>
              <Form.Control
                as="select"
                name={this.withNamespace(CAMPAIGN_NAME)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  get(touched, this.withNamespace(CAMPAIGN_NAME)) &&
                  get(errors, this.withNamespace(CAMPAIGN_NAME))
                    ? "is-invalid"
                    : null
                }
                value={get(values, this.withNamespace(CAMPAIGN_NAME))}
                isValid={
                  get(touched, this.withNamespace(CAMPAIGN_NAME)) &&
                  !get(errors, this.withNamespace(CAMPAIGN_NAME))
                }
                custom
              >
                <option key="" hidden>
                  Select campaign
                </option>
                {this.state.campaigns.map((campaign) => (
                  <option
                    key={campaign.name}
                    value={campaign.name}
                    disabled={
                      campaign.name.startsWith("Loading") ||
                      campaign.name.startsWith("error")
                    }
                  >
                    {campaign.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {get(errors, this.withNamespace(CAMPAIGN_NAME))}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Group controlId={this.withNamespace(SERVICE_ID)}>
              <Form.Label>Service</Form.Label>
              <Form.Control
                as="select"
                name={this.withNamespace(SERVICE_ID)}
                onChange={(e) =>
                  this.serviceSelected(e, handleChange, setFieldValue)
                }
                onBlur={handleBlur}
                className={
                  get(touched, this.withNamespace(SERVICE_ID)) &&
                  get(errors, this.withNamespace(SERVICE_ID))
                    ? "is-invalid"
                    : null
                }
                value={get(values, this.withNamespace(SERVICE_ID))}
                isValid={
                  get(touched, this.withNamespace(SERVICE_ID)) &&
                  !get(errors, this.withNamespace(SERVICE_ID))
                }
                custom
              >
                <option key="" hidden>
                  Select service
                </option>
                {this.state.services.map((service) => (
                  <option
                    key={service._id}
                    value={service._id}
                    disabled={
                      service._id === "loading" || service._id === "error"
                    }
                  >
                    {service.title}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {get(errors, this.withNamespace(SERVICE_ID))}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId={this.withNamespace(VARIATION_ID)}>
              <Form.Label>Variation</Form.Label>
              <Form.Control
                as="select"
                name={this.withNamespace(VARIATION_ID)}
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                className={
                  get(touched, this.withNamespace(VARIATION_ID)) &&
                  get(errors, this.withNamespace(VARIATION_ID))
                    ? "is-invalid"
                    : null
                }
                value={get(values, this.withNamespace(VARIATION_ID))}
                isValid={
                  get(touched, this.withNamespace(VARIATION_ID)) &&
                  !get(errors, this.withNamespace(VARIATION_ID))
                }
                custom
              >
                <option key="" hidden>
                  Select variation
                </option>
                {this.state.variations.map((variation) => (
                  <option
                    key={variation._id}
                    value={variation._id}
                    disabled={
                      variation._id === "loading" || variation._id === "error"
                    }
                  >
                    {variation.title}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {get(errors, this.withNamespace(VARIATION_ID))}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
      </>
    );
  }
}

export default BookingPatchBasicsSubForm;
