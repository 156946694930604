import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import {
  BookingsOverview,
  SimplePOS,
  LoginPage,
  PaymentsOverview,
} from "@/_routes";
import { PrivateRoute } from "@/_utils";
import { CurrentuserService, AuthenticationService } from "@/_services";
import { Can } from "@/_utils";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentuser: {
        username: localStorage.getItem("username"),
        role: localStorage.getItem("role"),
      },
    };
  }

  componentDidMount() {
    this.currentuserSubscription =
      CurrentuserService.getCurrentuser().subscribe((currentuser) => {
        if (currentuser) {
          this.setState({ currentuser: currentuser });
        } else {
          this.setState({ currentuser: { username: null, role: null } });
        }
      });
  }

  componentWillUnmount() {
    // unsubscribe to avoid memory leaks
    this.currentuserSubscription.unsubscribe();
  }

  render() {
    return (
      <Router>
        <Container>
          <Row>
            <Col>
              <Navbar expand="lg" bg="light" variant="light">
                <Navbar.Brand as={NavLink} to="/">
                  <img
                    src="/simpleposlogo.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="SimplePOS logo"
                  />{" "}
                  SimplePOS
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse id="basic-navbar-nav">
                  {!this.state.currentuser.username && (
                    <Nav>
                      <Nav.Link as={NavLink} to="/login">
                        Log in
                      </Nav.Link>
                    </Nav>
                  )}
                  <Can
                    perform="bookings:list"
                    yes={() => (
                      <Nav>
                        <Nav.Link as={NavLink} to="/pos">
                          POS
                        </Nav.Link>
                      </Nav>
                    )}
                  />
                  <Can
                    perform="paymentsoverview:visit"
                    yes={() => (
                      <Nav>
                        <Nav.Link as={NavLink} to="/paymentsoverview">
                          Payments
                        </Nav.Link>
                      </Nav>
                    )}
                  />
                  <Can
                    perform="bookingsoverview:visit"
                    yes={() => (
                      <Nav>
                        <Nav.Link as={NavLink} to="/bookingsoverview">
                          Bookings
                        </Nav.Link>
                      </Nav>
                    )}
                  />
                  <div className="mr-auto" />
                  {this.state.currentuser.username ? (
                    <Nav>
                      <Nav.Link onClick={AuthenticationService.logout}>
                        Log out {this.state.currentuser.username}
                      </Nav.Link>
                    </Nav>
                  ) : (
                    <Nav>
                      <Nav.Link disabled>Not logged in</Nav.Link>
                    </Nav>
                  )}
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>SimplePOS</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Switch>
                <PrivateRoute
                  path="/pos"
                  component={SimplePOS}
                  permission="bookings:list"
                />
                <PrivateRoute
                  path="/paymentsoverview"
                  component={PaymentsOverview}
                  permission="paymentsoverview:visit"
                />
                <PrivateRoute
                  path="/bookingsoverview"
                  component={BookingsOverview}
                  permission="bookingsoverview:visit"
                />
                <Route path="/login" component={LoginPage} />
                <Route path="/" component={LoginPage} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </Router>
    );
  }
}

export default App;
